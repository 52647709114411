body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 0.9;
  overflow-x: hidden;



}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/*--------------COLLAGE-----------------*/

#verde {
  background-color: #90c257;

}
#gold{
    background-color: #edd81c;
    color: #222322;
}

h1 {
  padding: 3%;
}

#negro {
  background-color: #222322;
}

#negro-oro {
  background-color: #222322;
  border: 3px solid #d0b51c;
}

.row>* {
  padding-left: 0 !important;
  padding-right: 0 !important;
}



#wapper {
  overflow: hidden;

}

#wapper img {
  width: 100%;
  height: 100%;
  transition: scale 400ms;
  max-width: auto;
  max-height: 600px;
}

#wapper:hover img {
  scale: 120%;
}






/*---------------NAVBAR-----------------*/

.offcanvas {
  width: 250px !important;
  

}

.navbar-nav {
  --bs-nav-link-color: #90c257 !important;
  
  font-size: smaller;
  transition: 400ms;
  z-index: 999999999999999999;

}

.navbar-nav:hover {
  --bs-nav-link-color: #90c257 !important;
  

}

#offcanva2{
  
  z-index: 999999999999999999;
  
}

.dropdown-item{
  z-index: 999999;
  position: relative!important;
}



/*************GYM-MODE**************/

#uno{
  --bs-nav-link-color: #edd81c !important;
  text-transform: uppercase;
  font-size: smaller;
  transition: 400ms;
}

#uno:hover{
  --bs-nav-link-hover-color: #ffffff !important;
}

#toggle{
  background: #edd81c;
}

#navbar{
  padding: 15px;
  background-color: #222322 !important;
}

#offcanva{
  background-color: #222322 ;
  z-index: 999999999999999999;
  color: #edd81c;
  border: 2px solid #edd81c;
}


#to-gym:hover {
  color: #edd81c;

}
#dropdown {
  font-size: 15px !important;
  color: #222322 !important;
}


#dropdown:hover {
  color: #96872f !important;
  background-color: transparent !important;

}

#navbar .nav-link.show {
  color: whitesmoke;
}
/****************************************/

.mb-3.navbar {
  padding: 15px;
  
}

.col-lg-12 {
  margin-bottom: -18px !important;
  
}

.dropdown-item {
  font-size: 15px !important;
  color: #90c257 !important;
}


.dropdown-item:hover {
  color: #2b2d28 !important;
  background-color: transparent !important;

}

/*---------------TEXTO-CENTRADO---------*/

#wapper2 {
  overflow: hidden;

}

#wapper2 img {
  width: 100%;
  max-height: 600px;
  transition: scale 400ms;
}

#wapper2:hover img {
  scale: 110%;
}

#wapper23 {
  overflow: hidden;

}

#wapper23 img {
  width: 100%;
  max-height: 600px;
  transition: scale 400ms;
}

#wapper23:hover img {
  scale: 110%;
}

#wapper23::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gold;
  opacity: 0.4; 
  z-index: 0; 
}

/*--------------FOOTER---------------*/


#final p {
  color: #eff0ef;
  font-size: 16px;
  text-align: center;
}

/*-------------WIDEPOD--------------*/

#widepod {
  background-color: #f9f9f8;
  color: #222322;
  padding: 40px;
}

#widepod3 {
  background-color: #222322;
  
  padding: 40px;
}




#texto2columnas {
  padding-left: 20%;
  padding-right: 20%;
  background-color: #f9f9f8;
  color: #222322;
}

#column {
  padding: 1%;

}


#row-max {
  width: 100%;
}

#tres-columnas {
  padding-right: 4%;
  padding-left: 4%;

  padding-top: 2%;
  background-color: #f9f9f8;
  color: #222322;
}



#imagenF{

    max-width: 450px;
}






/*-------------WIPES-----------------*/

#text-pls {
  font-size: 12px;
  text-align: left;
}

#text-pls-2 {
  font-size: 14px;
  text-align: left;

}

#text-pls-22 {
  font-size: 14px;
  text-align: left;

}

#text-pls-3 {
  font-size: 16px;
  text-align: left;
  
}

#text-pls-3 p {
  font-size: 16px;
  text-align: left;
  margin-left: 6% !important;
  padding-right: 6% !important;
}

#text-pls-32 {
  font-size: 14px;
  text-align: left;
  
}

#text-pls-32 p {
  font-size: 14px;
  text-align: left;
  margin-left: 6% !important;
  padding-right: 6% !important;
}

#text-pls-4 {
  font-size: 16px;
  padding: 1%;
  text-align: left;

}

#text-pls-5 {
  font-size: 16px;
  padding: 1%;
  text-align: center;


}

#text-pls-6 {
  font-size: 18px;
  padding: 1%;
  text-align: center;


}

#text-pls-7 {
  font-size: 16px;
  
  text-align: left;


}




#wapper3 {
  overflow: hidden;

}

#wapper3 img {
  width: 100%;
  max-height: 650px;
  transition: scale 400ms;
}

#wapper3:hover img {
  scale: 110%;
}

/*******************************HRS****************************/

#hr-green {
  border-top: 2px solid #90c257 !important;
  opacity: 1 !important;
}

#hr-green-s {
  border-top: 2px solid #90c257 !important;
  opacity: 1 !important;
  width: 50%;
}

#hr-black {
  border-top: 2px solid #000000 !important;
  opacity: 1 !important;
}

#hr-black-S {
  border-top: 2px solid #000000 !important;
  opacity: 1 !important;
  width: 50%;
}

#hr-gold {
  border-top: 2px solid #edd81c !important;
  opacity: 1 !important;
}




#txt-verde {
  color: #90c257;
}


/*---------------------ABOUT--------------------*/


#marriot {

  width: 450px;
  height: auto;
  margin-top: 10%;
  padding: 5%;
  filter: invert();

}

#anytime {

  width: 450px;
  height: auto;
  margin-top: 10%;
  padding: 20px;
  filter: invert();
}

#hilton {

  width: 250px;
  height: auto;
  padding: 1%;
  filter: invert();

}


/*----------------*/

.content {
  width: 100%;


}

.photos {
  width: 95%;
  margin-top: 10%;



}

.thumbnails {

  height: 500px;

  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: transform .3s;


}

.thumbnails img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 20%;
}

.black {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .7;

}

.black-oro {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .7;
  border: 3px solid #d0b51c;
}

.title {
  color: #fff;
  font-size: 2em;
  align-self: center;
  position: absolute;
  z-index: 10;
}


.black,
.title {
  transition: opacity .3s;
}

.black-oro,
.title {
  transition: opacity .3s;
}

.thumbnails:hover {
  cursor: pointer;
  transform: scale(1.05);
  z-index: 20;
}

.thumbnails:hover .black,
.thumbnails:hover .title {
  opacity: 0;
}

.thumbnails:hover .black-oro,
.thumbnails:hover .title {
  opacity: 0;
}

/****************Bton-scroll*******************/


.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #90c257;
  color: #497911;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 330ms;


}

.scroll-to-top:hover {
  background-color: #eff0ef;
  color: #000;
  border: 2px solid #90c257;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.scroll-to-top2 {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 40px;
  height: 40px;
  color: #497911;
  border: 2px solid #497911;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
}

.img-fluid33 {
  max-width: 100%;
  height: auto;
}

#image33{
  transform: scale(1.8);
  margin-bottom: 30%;
  transition: 330ms;
  
}

#image33:hover{
  transform: scale(2);
  margin-bottom: 30%;
  
  
}




.scroll-to-top2:hover {
  background-color: #eff0ef;
  color: #000;
  border: 2px solid #90c257;
}

.scroll-to-top2.show {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}


.scroll-to-top3 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #d0b51c;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 330ms;


}

.scroll-to-top3:hover {
  background-color: #eff0ef;
  color: #d0b51c;
  border: 2px solid #d0b51c;
}

.scroll-to-top3.show {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}




/************Butons**************/


.botoncito {
  --c: #222322;
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.9em;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  background-color: transparent;
  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
  padding-bottom: 45px;
}

.botoncito span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.botoncito:hover {
  color: rgb(255, 255, 255);
  letter-spacing: 0.1em;
}

.botoncito:hover span {
  transform: translateY(0) scale(2);
}

.botoncito span:nth-child(1) {
  --n: 1;
}

.botoncito span:nth-child(2) {
  --n: 2;
}

.botoncito span:nth-child(3) {
  --n: 3;
}

.botoncito span:nth-child(4) {
  --n: 4;
}


/***************************/

.botoncito2 {
  --c: #90c257;
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.9em;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;

  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
  background-color: transparent;
  padding-bottom: 45px;
}

.botoncito2 span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.botoncito2:hover {
  color: black;
  letter-spacing: 0.1em;
}

.botoncito2:hover span {
  transform: translateY(0) scale(2);
}

.botoncito2 span:nth-child(1) {
  --n: 1;
}

.botoncito2 span:nth-child(2) {
  --n: 2;
}

.botoncito2 span:nth-child(3) {
  --n: 3;
}

.botoncito2 span:nth-child(4) {
  --n: 4;
}

.botoncito3 {
  --c: #d0b51c;
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.9em;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;

  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
  background-color: transparent;
  padding-bottom: 45px;

}

.botoncito3 span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.botoncito3:hover {
  color: black;
  letter-spacing: 0.1em;
}

.botoncito3:hover span {
  transform: translateY(0) scale(2);
}

.botoncito3 span:nth-child(1) {
  --n: 1;
}

.botoncito3 span:nth-child(2) {
  --n: 2;
}

.botoncito3 span:nth-child(3) {
  --n: 3;
}

.botoncito3 span:nth-child(4) {
  --n: 4;
}



.image-slider img {

  transition: opacity 0.5s ease-in-out;
}

.image-slider img.active {
  opacity: 1;
}

/**************Welcome*************/


.image-container {
  position: relative;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border: 4px solid #d0b51c;


}

.text-overlay2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  z-index: 99999;

}

.titulo {

  font-size: 80px;
  text-align: left;
}

.titulo2 {

  font-size: 80px;
  text-align: center;

}

.titulo21 {

  font-size: 50px;
  text-align: center;

}

.titulo3 {

  font-size: 80px;
  text-align: center;
  color: #edd81c;
  -webkit-text-stroke: 2px black;
  
  

}

#menor {
  font-size: 19px;
}
#menor2 {
  font-size: 19px;
  color: #000;
}


#text-gold {
  color: #d0b51c;
}
#text-white{
  color: #eff0ef;
}



#image {
  width: 140px;
  margin-top: -30px;

  transition: scale 400ms;

}

#image2 {
  width: 140px;
  margin-top: -30px;
  padding: 9px;
  transition: scale 400ms;

}

#image:hover {
  scale: 120%;
}

#image2:hover {
  scale: 110%;
}

#image3 {
  width: 100px;
  margin-top: -30px;
  margin-left: 80px;
  transition: scale 400ms;

}

#image4 {
  width: 100px;
  transition: scale 400ms;

}
#image5 {
  
  transition: scale 400ms;

}

#image3:hover {
  scale: 110%;
}

#image4:hover {
  scale: 110%;
}

#image5:hover {
  scale: 110%;
}

/****************languaje buttons **********/

#languaje-b {
  border: none;
  background-color: transparent;
  transition: scale 400ms;

}

#languaje-b:hover {
  scale: 120%;
}


/***********************MEDIA QUERYS*******************************/
@media (max-width: 1375px) {

  

  #menor {
    display: none;
  }

  #text-pls-3 p {
    font-size: 14px;
    text-align: left;
    margin-left: 6% !important;
    padding-right: 6% !important;
}
}
@media (max-width: 1075px) {


  #logo {
    width: 300px;
  }

  /************ABOUT*************/
  #menor {
    display: none;
  }

  .titulo2 {
    font-size: 55px;
  }
  .titulo21 {
    font-size: 25px;
  }
  .titulo3 {
    font-size: 55px;
  }

  .botoncito {
    display: none;
  }

  /************MENU**************/
  .text-overlay {
    height: 85%;

  }

  .botoncito3 {
    scale: 0.6;
    margin-top: -10px;
  }

  .text-overlay h1 {
    font-size: 10px;

  }

  .text-overlay h2 {
    font-size: 8px;

  }

  .text-overlay2 h1 {
    font-size: 25px;

  }



}

@media (max-width: 495px) {
  #logo {
    width: 200px;
  }

  #text-pls-3 p {
    
    margin-left: 6% !important;
    padding-right: 6% !important;
  }

  #text-pls-2 p {
    
    margin-left: 6% !important;
    padding-right: 6% !important;
  }

  #text-pls-6 p {
    
    margin-left: 6% !important;
    padding-right: 6% !important;
  }

  #text-pls-7 {
    font-size: 16px;
    padding: 1%;
    text-align: left;
  
  
  }
  #marriot {

    transform: scale(0.6);
  
  }
  
  #anytime {
  
    transform: scale(0.6);
  }
  
  #hilton {
  
    transform: scale(0.7);
  
  }

  /************ABOUT*************/
  #menor {
    display: none;
  }
  #menor2 {
    display: none;
  }

  .titulo2 {
    font-size: 35px;
  }

  .botoncito {
    display: none;
  }

  /************MENU**************/


  .img-fluid {
    min-height: 200px;
  }


  .text-overlay {
    height: 55%;

  }

  .botoncito3 {
    scale: 0.6;
    margin-top: -10px;
  }

  .text-overlay h1 {
    font-size: 10px;

  }

  .text-overlay h2 {
    font-size: 8px;

  }

  .text-overlay2 h1 {
    font-size: 25px;

  }

  /**************WHERE***********/
  #wapper2 img{
    transform: scale(1.1);
  }

  #wapper3 img{
    transform: scale(1.1);
  }

  #image {
    
    transform: scaleY(0.7);
  }

  #image2 {
    transform: scaleX();
    transform: scaleY(0.7);
    margin-top: 20px;
  }

  #image3 {
    min-height: 20px;
    min-width: 160px;
    margin-top: 20px;
    
  }
  #image5 {
    
    transform: scale(0.6);
    
    
    
  }
  #image4 {
    min-height: 20px;
    min-width: 120px;
    
    
  }

  /*****************3COLUMS***************/

  #check {
    margin-top: 10px;

  }

}


